import React, { useState } from 'react';
import {
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

function CardFlagManagement({
  values = [],
  addNewValue = () => {},
  removeValue = () => {},
  placeholder,
  ...rest
}) {
  const [text, setText] = useState('');

  const alreadyExist = values.some(
    (value) => value.toLowerCase() === text.toLowerCase(),
  );

  const mustShowTable = values.length > 0;

  function handleSubmit(event) {
    event?.preventDefault();

    if (alreadyExist) {
      return;
    }

    addNewValue(text);
    setText('');
  }

  return (
    <Flex direction="column" as="form" onSubmit={handleSubmit} {...rest}>
      <FormControl mb={2}>
        <FormLabel>Digite o nome da bandeira</FormLabel>
        <Flex>
          <Input
            isInvalid={alreadyExist}
            mr={2}
            name="flag"
            value={text}
            placeholder={placeholder}
            onChange={(event) => setText(event.target.value)}
          />
          <Button colorScheme="teal" type="submit">
            <AddIcon />
          </Button>
        </Flex>
        {alreadyExist && (
          <Text color="red" fontSize="sm" py={1}>
            Bandeira duplicada
          </Text>
        )}
      </FormControl>

      {mustShowTable && (
        <Table variant="simple" size="md">
          <Thead>
            <Tr>
              <Th>Bandeira</Th>
            </Tr>
          </Thead>
          <Tbody>
            {values.map((flag) => (
              <Tr key={flag}>
                <Td>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text>{flag}</Text>
                    <Button
                      variant="link"
                      colorScheme="red"
                      fontWeight="normal"
                      onClick={() => removeValue(flag)}
                    >
                      Excluír
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Flex>
  );
}

export default React.memo(CardFlagManagement);
