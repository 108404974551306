import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Flex, Button, Divider, Text, Fade } from '@chakra-ui/react';

import PageHeading from '@components/PageHeading';
import api from '@services/api';
import Details from './components/Details';

function Product() {
  const history = useHistory();
  const { productId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    async function loadData() {
      if (!productId) return;

      try {
        const { data } = await api.get(`/products/${productId}`);
        setProduct(data);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsFetching(false);
      }
    }

    loadData();
  }, [productId]);

  function handleEditProduct() {
    history.push({ pathname: '/edit-product', search: `id=${productId}` });
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <PageHeading>Detalhes do produto</PageHeading>

        <Fade in={!!product}>
          <Button colorScheme="teal" onClick={handleEditProduct}>
            Editar produto
          </Button>
        </Fade>
      </Flex>

      <Divider my={4} />
      {isFetching && <Text>Buscando os dados...</Text>}
      {!isFetching && !product && <Text>Produto não encontrado</Text>}
      {product && <Details product={product} />}
    </>
  );
}

export default Product;
