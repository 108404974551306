import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Button, Divider, Text, Stack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import PageHeading from '@components/PageHeading';
import api from '@services/api';

export default function Page() {
  const history = useHistory();

  const [complements, setComplements] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const handleNewComplementClick = useCallback(() => {
    history.push('/new-complement');
  }, [history]);

  const handleComplementClick = useCallback(
    (id) => {
      history.push(`/edit-complement/${id}`);
    },
    [history],
  );

  useEffect(() => {
    async function loadComplements() {
      try {
        const { data } = await api.get('stores/complements');
        setComplements(data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsFetching(false);
      }
    }

    loadComplements();
  }, []);

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <PageHeading>Complementos</PageHeading>

        <Button colorScheme="teal" onClick={handleNewComplementClick}>
          Novo
        </Button>
      </Flex>

      <Divider my={4} />

      {isFetching && <Text>Carregando...</Text>}
      {!isFetching && complements.length === 0 && (
        <Text>Nenhum complemento cadastrado</Text>
      )}

      <Stack spacing={4}>
        {complements.map(({ id, name, options, products, categories }) => (
          <Flex
            cursor="pointer"
            transition="all .35s ease"
            _hover={{
              shadow: 'xl',
            }}
            p={4}
            rounded={4}
            shadow="lg"
            border="1px"
            borderColor="gray.200"
            key={id}
            direction="column"
            onClick={() => handleComplementClick(id)}
          >
            <Text fontWeight="bold" fontSize="lg" mb={2}>
              {name}
            </Text>
            <Text mb={1}>
              <strong>Opções: </strong>
              {options.map((option) => option.name).join(`, `) || 'Nenhuma'}
            </Text>
            <Text mb={1}>
              <strong>Produtos associados: </strong>
              {products.length || 'Nenhum'}
            </Text>
            <Text mb={1}>
              <strong>Categorias associadas: </strong>
              {categories.length || 'Nenhum'}
            </Text>
          </Flex>
        ))}
      </Stack>
    </>
  );
}
