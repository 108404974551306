import React, { useCallback, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Text,
  Box,
  useToast,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';

import api from '@services/api';

export default function DeleteProduct({ productId }) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  function openToast(settings = {}) {
    toast({
      duration: 5000,
      isClosable: true,
      position: 'top',
      ...settings,
    });
  }
  const handleDelete = useCallback(async () => {
    try {
      setIsLoading(true);

      await api.delete(`/products/${productId}`);

      openToast({
        type: 'Tudo certo',
        description: 'Produto excluído com sucesso!',
        status: 'success',
      });

      history.push('/products');
    } catch (err) {
      console.error(err);

      openToast({
        type: 'Ops!',
        description: 'Não foi possível excluír este produto!',
        status: 'error',
      });
    } finally {
      setIsLoading(false);
      modalOnClose();
    }
  }, [modalOnClose, productId, history]);

  return (
    <>
      <Button colorScheme="red" title="Excluir produto" onClick={modalOnOpen}>
        <DeleteIcon />
      </Button>

      <Box position="absolute">
        <Modal
          blockScrollOnMount
          isOpen={modalIsOpen}
          onClose={modalOnClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent marginRight={4} marginLeft={4}>
            <ModalHeader>Atenção</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb="1rem">Você deseja realmente excluir o produto?</Text>
            </ModalBody>

            <ModalFooter>
              <Button
                disabled={isLoading}
                colorScheme="blue"
                mr={3}
                onClick={modalOnClose}
              >
                Não
              </Button>

              <Button
                isLoading={isLoading}
                colorScheme="red"
                mr={3}
                onClick={handleDelete}
              >
                Sim, tenho
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
