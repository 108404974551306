import React, { useMemo, useState } from 'react';
import { FormControl, FormLabel, Select, Flex } from '@chakra-ui/react';

import CategoryManager from '@components/CategoryManager';
import { CategoryProvider, useCategory } from '@hooks/category';

function CategoryInput({ name, defaultValue }) {
  const [value, setValue] = useState(defaultValue);

  const { loading, categories } = useCategory();

  const firstLabel = useMemo(() => {
    if (loading) return `Carregando categorias`;

    if (!loading && categories.length === 0)
      return `Nenhuma categoria cadastrada`;

    return 'Selecione uma categoria';
  }, [loading, categories]);

  return (
    <FormControl isRequired>
      <Flex justify="space-between" position="relative">
        <FormLabel fontWeight="bold">Categoria</FormLabel>
        <CategoryManager />
      </Flex>

      <Select
        name={name}
        variant="outline"
        value={value}
        onChange={({ target }) => setValue(target.value)}
      >
        <option disabled>{firstLabel}</option>
        {categories.map(({ id, name: categoryName }) => (
          <option key={id} value={id}>
            {categoryName}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

export default React.memo(({ ...rest }) => (
  <CategoryProvider>
    <CategoryInput {...rest} />
  </CategoryProvider>
));
