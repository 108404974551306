import React, { useState, useEffect } from 'react';
import {
  Flex,
  Heading,
  Input,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Link,
  Image,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Text,
} from '@chakra-ui/react';

import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '@hooks/auth';
import api from '@services/api';

function Login() {
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();
  const { signIn } = useAuth();
  const [isSending, setIsSending] = useState(false);
  const [confirmationEmailSuccess, setConfirmationEmailSuccess] =
    useState(false);

  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const verificationStatus = query?.get('verification_status');

    if (!verificationStatus) return;

    setConfirmationEmailSuccess(verificationStatus === 'success');

    query.delete('verification_status');

    history.replace({
      search: query.toString(),
    });

    modalOnOpen();
  }, [location, modalOnOpen, history]);

  async function handleSubmit(event) {
    setIsSending(true);

    try {
      event.preventDefault();

      const { target: form } = event;

      const email = form.email.value;
      const password = form.password.value;

      const { data } = await api.post('sessions', { email, password });
      const { token, user } = data;

      signIn({ token, user });
    } catch (error) {
      console.log({ error });
      toast({
        title: 'Erro.',
        description:
          error?.response?.data ||
          error?.message ||
          'Tente novamente mais tarde.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      setIsSending(false);
    }
  }

  return (
    <>
      <Flex
        height="100vh"
        direction="column"
        alignItems="center"
        justifyContent="center"
        p={6}
      >
        <Image src="/logo.svg" width={['175px', '200px', '240px']} mb={8} />
        <Flex
          as="form"
          p={8}
          rounded={6}
          direction="column"
          width="100%"
          maxWidth="400px"
          shadow="lg"
          onSubmit={handleSubmit}
          border="1px"
          borderColor="gray.200"
        >
          <Heading mb={6} size="md" alignSelf="center">
            Acessar a loja
          </Heading>

          <FormControl isRequired>
            <FormLabel fontWeight="bold">Email</FormLabel>
            <Input mb={4} type="email" name="email" required />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontWeight="bold">Senha</FormLabel>
            <Input type="password" mb={8} name="password" required />
          </FormControl>
          <Button
            colorScheme="teal"
            size="lg"
            type="submit"
            variant="outline"
            disabled={isSending}
          >
            Acessar
          </Button>

          <Link
            mt={5}
            color="teal.500"
            size="md"
            alignSelf="center"
            textAlign="center"
            onClick={() => history.push('/register')}
          >
            Ainda não tem conta? Cadastre-se agora!
          </Link>
        </Flex>
      </Flex>

      <Modal blockScrollOnMount isOpen={modalIsOpen} onClose={modalOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {confirmationEmailSuccess
              ? 'Email confirmado com sucesso'
              : 'Não foi possível confirmar seu email'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="1rem">
              {confirmationEmailSuccess
                ? 'Parabéns! Agora você já pode acessar sua conta!'
                : 'Algo falhou! Tente clicar no link de confirmação novamente!'}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={modalOnClose}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Login;
