import React from 'react';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  CheckboxGroup,
  Checkbox,
  Stack,
} from '@chakra-ui/react';

function ChooseItem({ items = [], value, onChange }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        _hover={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        color="teal"
        onClick={onOpen}
      >
        Selecionar
      </Box>
      <Modal
        blockScrollOnMount={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent m={2}>
          <ModalHeader>Selecione as categorias</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CheckboxGroup value={value} size="lg" onChange={onChange}>
              <Stack>
                {items.map(({ id, slug, name }) => (
                  <Checkbox key={slug} value={id}>
                    {name}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}

export default React.memo(ChooseItem);
