import React, { useState, useEffect } from 'react';
import {
  Button,
  Spinner,
  Flex,
  Text,
  Link,
  Badge,
  Image,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import PageHeading from '@components/PageHeading';
import api from '@services/api';
import toCurrency from '@utils/number-format';

const Product = React.memo(
  ({ index, id, image, name, description, price, active }) => (
    <RouterLink to={`/edit-product?id=${id}`}>
      <Flex
        cursor="pointer"
        transition="all .35s ease"
        _hover={{
          shadow: 'xl',
        }}
        p={2}
        rounded={4}
        shadow="lg"
        border="1px"
        borderColor="gray.200"
        mt={index > 0 ? 4 : 0}
      >
        <Image
          src={image?.url || '/full-logo.webp'}
          height={['70px', '90px']}
          width={['70px', '90px']}
          objectFit="cover"
          mr={4}
          rounded={4}
        />
        <Flex flex="1" direction="column">
          <Flex>
            <Text fontWeight="600" mr={2}>
              {name}
            </Text>
            {active ? (
              <Badge alignSelf="center" rounded={4} colorScheme="green">
                Ativado
              </Badge>
            ) : (
              <Badge alignSelf="center" rounded={4} colorScheme="red">
                Desativado
              </Badge>
            )}
          </Flex>
          <Text>{description}</Text>
          <Text mt={2} fontWeight="600" color="teal.600">
            {toCurrency(price)}
          </Text>
        </Flex>
      </Flex>
    </RouterLink>
  ),
);

export default function ProductsPreview() {
  const [products, setProducts] = useState([]);
  const [isFetchingProducts, setIsFetchingProducts] = useState(true);
  const [hasStore, setHasStore] = useState(true);

  const shouldRenderRegisterProducts =
    products.length === 0 && !isFetchingProducts && hasStore;

  useEffect(() => {
    async function fetchProducts() {
      try {
        setIsFetchingProducts(true);
        const { data } = await api.get('/products?limit=3');
        setProducts(data);
      } catch (err) {
        if (err?.response?.data?.message === 'Store not found') {
          setHasStore(false);
        }
      } finally {
        setIsFetchingProducts(false);
      }
    }

    fetchProducts();
  }, []);

  return (
    <Flex flexDirection="column" mb={4}>
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        <PageHeading>Produtos</PageHeading>
        {isFetchingProducts && <Spinner size="sm" />}
      </Flex>

      {products?.length > 0 && (
        <Flex flexDirection="column" alignItems="stretch" mb={4}>
          {products.map((product, index) => (
            <Product key={product.id} {...product} index={index} />
          ))}
        </Flex>
      )}

      {products?.length > 0 && (
        <RouterLink to="/products">
          <Button colorScheme="teal" w="100%" size="lg">
            Ver todos
          </Button>
        </RouterLink>
      )}

      {shouldRenderRegisterProducts && (
        <Text>
          Você não tem produtos,{' '}
          <RouterLink to="/edit-product">
            <Link color="teal" as="span">
              crie agora!
            </Link>
          </RouterLink>
        </Text>
      )}

      {!hasStore && (
        <Text>
          Você precisa criar uma loja antes dos produtos.{' '}
          <RouterLink to="/edit-store">
            <Link color="teal" as="span">
              Crie uma agora!
            </Link>
          </RouterLink>
        </Text>
      )}
    </Flex>
  );
}
