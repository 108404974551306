import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
} from '@chakra-ui/react';

function ConfirmDelete({
  isOpen,
  onClose,
  onConfirm,
  dialogTitle = '',
  dialogContent = '',
}) {
  const cancelRef = useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent width="calc(100% - 2rem)" maxWidth="400px">
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            dangerouslySetInnerHTML={{ __html: dialogTitle }}
          />

          <AlertDialogBody
            dangerouslySetInnerHTML={{ __html: dialogContent }}
          />

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Não
            </Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3}>
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default ConfirmDelete;
