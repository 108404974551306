import React, { useState, useEffect } from 'react';
import {
  Heading,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useToast,
  Text,
  Checkbox,
  Flex,
} from '@chakra-ui/react';
import CurrencyInput from 'react-currency-input';
import { useHistory, useLocation } from 'react-router-dom';

import api from '@services/api';
import ImageInput from './components/ImageInput';
import CategoryInput from './components/CategoryInput';
import DeleteProduct from './components/DeleteProduct';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function EditProduct() {
  const toast = useToast();
  const history = useHistory();
  const query = useQuery();
  const productId = query.get('id');
  const [product, setProduct] = useState(null);
  const [isFetching, setIsFetching] = useState(!!productId);
  const [localImageFile, setLocalImageFile] = useState(null);
  const [productPrice, setProductPrice] = useState(0);

  useEffect(() => {
    async function loadData() {
      if (!productId) return;

      try {
        const { data } = await api.get(`/products/${productId}`);
        setProduct(data);
        setProductPrice(data?.price || 0);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsFetching(false);
      }
    }

    loadData();
  }, [productId]);

  function openToast(settings = {}) {
    toast({
      duration: 5000,
      isClosable: true,
      position: 'top',
      ...settings,
    });
  }

  async function uploadImage(file) {
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await api.post('files', formData);

    const { id, url } = data;

    return { id, url };
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      const { target: form } = event;

      const image = localImageFile ? await uploadImage(localImageFile) : null;

      const data = {
        name: form.name.value,
        description: form.description.value,
        price: productPrice,
        image_id: image?.id || null,
        active: form.active.checked,
        category_id: form.category.value || null,
      };

      if (productId) {
        await api.put(`products/${productId}`, data);
      } else {
        await api.post('products', data);
      }

      openToast({
        title: 'Tudo certo!',
        description: 'Os dados foram enviados com sucesso!!',
        status: 'success',
      });

      history.push('/products');
    } catch (error) {
      console.log({ error });
      openToast({
        title: 'Opss!',
        description: 'Não foi possível realizar o registro!',
        status: 'error',
      });
    }
  }

  if (isFetching) {
    return <Text>Aguarde, estamos buscando o produto...</Text>;
  }

  if (productId && !product) {
    return <Text>Não foi possível encontrar este produto</Text>;
  }

  return (
    <>
      <Flex
        justifyContent="space-between"
        width="100%"
        maxWidth="400px"
        mb={5}
        alignItems="center"
        alignSelf="center"
      >
        <Heading size="md">
          {productId ? `Editar Produto` : 'Criar novo produto'}
        </Heading>

        {productId && <DeleteProduct productId={productId} />}
      </Flex>

      <Grid
        as="form"
        direction="column"
        shadow="lg"
        border="1px"
        borderColor="gray.200"
        p={4}
        rounded={4}
        gap={4}
        maxWidth="400px"
        width="100%"
        margin="0 auto"
        onSubmit={handleSubmit}
      >
        <ImageInput
          preview={product?.image?.url}
          onImageChange={setLocalImageFile}
        />
        <FormControl isRequired>
          <FormLabel fontWeight="bold">Nome:</FormLabel>
          <Input
            name="name"
            required
            defaultValue={product?.name || undefined}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontWeight="bold">Preço:</FormLabel>
          <Input
            as={CurrencyInput}
            decimalSeparator=","
            thousandSeparator="."
            precision="2"
            required
            value={productPrice || 0}
            onChangeEvent={(_, __, value) => setProductPrice(value)}
          />
        </FormControl>

        <CategoryInput
          name="category"
          defaultValue={product?.category?.id || undefined}
        />

        <FormControl>
          <FormLabel fontWeight="bold">Descrição</FormLabel>
          <Textarea
            name="description"
            defaultValue={product?.description || undefined}
          />
        </FormControl>
        <Checkbox
          mb={4}
          defaultChecked={product ? product?.active : true}
          colorScheme="teal"
          name="active"
        >
          Ativado
        </Checkbox>
        <Button colorScheme="teal" size="lg" type="submit">
          {productId ? `Salvar` : 'Criar produto'}
        </Button>
      </Grid>
    </>
  );
}

export default EditProduct;
