import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import api from '@services/api';

const CategoryContext = createContext({});

const CategoryProvider = ({ children }) => {
  const [loading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [deletingCategoryId, setDeletingCategoryId] = useState(null);

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoading(true);
        const { data } = await api.get('/stores/categories');
        setCategories(data);
      } catch (err) {
        console.log({ err });
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, []);

  const addNewCategory = useCallback(
    async ({ name, onSuccess = () => {}, onError = () => {} }) => {
      try {
        setIsLoading(true);
        const { data } = await api.post('/stores/categories', { name });
        setCategories((values) => [data, ...values]);
        onSuccess();
      } catch (err) {
        console.log({ err });
        onError();
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const editCategory = useCallback(
    async ({ name, id, onSuccess = () => {}, onError = () => {} }) => {
      try {
        setIsLoading(true);
        setEditingCategoryId(id);
        const { data } = await api.put(`/stores/categories/${id}`, { name });
        setCategories((values) =>
          values.map((value) =>
            value.id === id ? { ...value, ...data } : value,
          ),
        );
        onSuccess();
      } catch (err) {
        console.log({ err });
        onError();
      } finally {
        setIsLoading(false);
        setEditingCategoryId(null);
      }
    },
    [],
  );

  const deleteCategory = useCallback(
    async ({ id, onSuccess = () => {}, onError = () => {} }) => {
      try {
        setIsLoading(true);
        setDeletingCategoryId(id);

        await api.delete(`/stores/categories/${id}`);
        setCategories((values) => values.filter((value) => value.id !== id));

        onSuccess();
      } catch (err) {
        console.log({ err });
        onError();
      } finally {
        setIsLoading(false);
        setDeletingCategoryId(null);
      }
    },
    [],
  );

  return (
    <CategoryContext.Provider
      value={{
        categories,
        loading,
        addNewCategory,
        editCategory,
        editingCategoryId,
        deleteCategory,
        deletingCategoryId,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

function useCategory() {
  const context = useContext(CategoryContext);

  if (!context) {
    throw new Error('useCategory must be used within a CategoryProvider');
  }

  return context;
}

export { CategoryProvider, useCategory };
