import React, { useState } from 'react';

import { FormControl, FormLabel, Input, Image } from '@chakra-ui/react';

function AvatarImageInput({ preview, onImageChange }) {
  const [localPreview, setLocalPreview] = useState(preview);

  function handleInputChange(e) {
    setLocalPreview(URL.createObjectURL(e.target.files[0]));
    onImageChange(e.target.files[0]);
  }

  return (
    <FormControl
      display="flex"
      width="8rem"
      height="8rem"
      border="1px"
      borderColor="gray.200"
      rounded={10}
      justifyContent="center"
      alignItems="stretch"
      textAlign="center"
      color="gray.500"
      overflow="hidden"
      position="absolute"
      bottom={0}
      transform="translate(1rem, 50%)"
      transition="all 0.35s ease"
      _hover={{
        filter: 'brightness(0.9)',
      }}
      background="white"
    >
      <FormLabel
        cursor="pointer"
        textAlign="center"
        margin={0}
        lineHeight="8rem"
        width="100%"
      >
        {localPreview ? (
          <Image
            objectFit="cover"
            width="100%"
            height="100%"
            src={localPreview}
            alt=""
            transition="all 0.35s ease"
            _groupHover={{
              transform: 'scale(1.2)',
            }}
          />
        ) : (
          <>Adicionar foto</>
        )}
        <Input display="none" type="file" onChange={handleInputChange} />
      </FormLabel>
    </FormControl>
  );
}

export default AvatarImageInput;
