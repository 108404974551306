import React, { useState, forwardRef, useImperativeHandle } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Grid,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Button,
} from '@chakra-ui/react';

import CurrencyInput from 'react-currency-input';

const ManageOption = forwardRef(({ onSave = () => {} }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [id, setId] = useState(undefined);
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);

  function handleClose() {
    setId(undefined);
    setName('');
    setPrice('');

    onClose();
  }

  function handleSave() {
    if (!name) {
      // eslint-disable-next-line no-alert
      alert('O nome é obrigatório');
      return;
    }

    onSave({
      name,
      price: price || 0,
      id,
    });

    handleClose();
  }

  useImperativeHandle(ref, () => ({
    open: (optionToEdit) => {
      if (optionToEdit) {
        setId(optionToEdit.id);
        setName(optionToEdit.name);
        setPrice(optionToEdit.price);
      }

      onOpen();
    },
    close: handleClose,
  }));

  return (
    <Modal
      onClose={handleClose}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent m={2}>
        <ModalHeader>Nova opção</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Grid} gap={4}>
          <FormControl isRequired>
            <FormLabel fontWeight="bold" htmlFor="name">
              Nome
            </FormLabel>
            <Input
              id="name"
              placeholder="Ex: Cheddar"
              value={name || ''}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold" htmlFor="price">
              Preço
            </FormLabel>
            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input
                as={CurrencyInput}
                id="price"
                placeholder="Ex: R$ 1,50"
                decimalSeparator=","
                thousandSeparator="."
                precision="2"
                value={price || 0}
                onChangeEvent={(_, __, value) => setPrice(value)}
              />
            </InputGroup>
          </FormControl>

          <Button mt={2} colorScheme="teal" onClick={handleSave}>
            Salvar
          </Button>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
});

export default ManageOption;
