import React, { useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import {
  Grid,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Input,
  FormHelperText,
  Button,
  useToast,
  Heading,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

import PageHeading from '@components/PageHeading';
import ConfirmDelete from '@components/ConfirmDelete';
import excludeSecondsFromTime from '@utils/exclude-seconds-from-time';
import api from '@services/api';

const DAY_NAMES = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export default function EditStoreSchedule() {
  const { register, control, handleSubmit, reset: formReset } = useForm();

  const [businessDays, setBusinessDays] = useState([]);
  const [deletingBusinessDay, setDeletingBusinessDay] = useState(null);
  const [deletingBusinessDayId, setDeletingBusinessDayId] = useState(null);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [editingDay, setEditingDay] = useState(null);

  const toast = useToast();

  const dayOptions = useMemo(
    () =>
      [0, 1, 2, 3, 5, 6].map((value) => ({
        value,
        text: DAY_NAMES[value],
        disabled: businessDays
          .filter(({ day }) => day !== editingDay?.day)
          .some(({ day }) => day === value),
      })),
    [editingDay, businessDays],
  );

  function openToast(settings = {}) {
    toast({
      duration: 5000,
      isClosable: true,
      position: 'top',
      ...settings,
    });
  }

  async function handleFormSubmit(values) {
    if (isSendingRequest) {
      return;
    }

    const data = {
      ...values,
      day: Number(values.day),
    };

    const [startHour, startMinute] = data.start
      .split(':')
      .map((v) => Number(v));

    const [endHour, endMinute] = data.end.split(':').map((v) => Number(v));

    if (startHour > 23 || endHour > 23 || startMinute > 59 || endMinute > 59) {
      openToast({
        title: 'Formato incorreto',
        description: 'A hora deve estar entre 00:00 e 23:59',
        status: 'error',
      });
      return;
    }

    const startDate = new Date();
    startDate.setHours(startHour);
    startDate.setMinutes(startMinute);

    const endDate = new Date();
    endDate.setHours(endHour);
    endDate.setMinutes(endMinute);

    if (startDate > endDate) {
      openToast({
        title: 'Formato incorreto',
        description: 'A hora de abertura deve ser menor que a de fechamento',
        status: 'error',
      });
      return;
    }

    try {
      setIsSendingRequest(true);
      const apiMethod = editingDay ? api.put : api.post;
      const endPoint = editingDay
        ? `stores/business-days/${editingDay.id}`
        : 'stores/business-days';

      const { data: response } = await apiMethod(endPoint, data);

      if (editingDay) {
        setBusinessDays(
          businessDays.map((businessDay) => {
            if (businessDay.id === editingDay.id) {
              return response;
            }

            return businessDay;
          }),
        );

        setEditingDay(null);
      } else {
        setBusinessDays(
          [...businessDays, response].sort((day1, day2) => day1.day - day2.day),
        );
      }

      formReset({
        day: '',
        start: '',
        end: '',
      });
    } catch (error) {
      openToast({
        title: 'Erro ao cadastrar',
        description: error.response.data.message,
        status: 'error',
      });
    } finally {
      setIsSendingRequest(false);
    }
  }

  function handleDeleteButtonClick(id) {
    setDeletingBusinessDay(businessDays.find((day) => day.id === id));
  }

  function handleEditButtonClick(id) {
    const dayToEdit = businessDays.find((day) => day.id === id);
    setEditingDay(dayToEdit);

    formReset({
      day: dayToEdit.day,
      start: excludeSecondsFromTime(dayToEdit.start),
      end: excludeSecondsFromTime(dayToEdit.end),
    });
  }

  function handleCancelEdit() {
    setEditingDay(null);
    formReset({
      day: '',
      start: '',
      end: '',
    });
  }

  async function handleDeleteConfirm(deletingId) {
    setDeletingBusinessDayId(deletingId);
    setDeletingBusinessDay(null);

    try {
      await api.delete(`stores/business-days/${deletingBusinessDay.id}`);
      setBusinessDays(businessDays.filter((day) => day.id !== deletingId));
    } catch (error) {
      console.log({ error });
      openToast({
        title: 'Erro ao deletar horário',
        description: error.response || 'Tente novamente mais tarde',
        status: 'error',
      });
    } finally {
      setDeletingBusinessDayId(null);
    }
  }

  useEffect(() => {
    api.get('/stores/business-days').then(({ data }) => {
      setBusinessDays(data);
    });
  }, []);

  return (
    <>
      <Flex direction="column" alignItems="stretch">
        <Flex mb={4}>
          <PageHeading>Horário de funcionamento</PageHeading>
        </Flex>

        <Flex
          direction="column"
          p={4}
          shadow="lg"
          border="1px"
          borderColor="gray.200"
          rounded={4}
        >
          <Flex mb={4} justifyContent="space-between">
            <Heading fontSize={['lg', 'xl']}>
              {editingDay ? 'Editar horário' : 'Adicionar horário'}
            </Heading>
            {editingDay && (
              <Button
                variant="link"
                fontWeight="regular"
                colorScheme="teal"
                onClick={handleCancelEdit}
              >
                Cancelar edição
              </Button>
            )}
          </Flex>

          <Grid
            as="form"
            templateColumns={['1fr', '1fr', '1fr 1fr 1fr auto']}
            gap={4}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Dia da semana</FormLabel>

              <Select {...register('day')} placeholder="Selecione o dia">
                {dayOptions.map(({ value, text, disabled }) => (
                  <option
                    key={String(value)}
                    value={value}
                    disabled={disabled || undefined}
                  >
                    {text}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontWeight="bold">Hora da abertura</FormLabel>
              <Controller
                control={control}
                name="start"
                render={({ field: { value, ...field } }) => (
                  <Input
                    {...field}
                    value={value || ''}
                    as={InputMask}
                    mask="99:99"
                    placeholder="Ex: 18:00"
                  />
                )}
              />

              <FormHelperText fontSize="sm">
                Formato 24 hrs (00:00 - 23:59)
              </FormHelperText>
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontWeight="bold">Hora de fechamento</FormLabel>
              <Controller
                control={control}
                name="end"
                render={({ field: { value, ...field } }) => (
                  <Input
                    {...field}
                    value={value || ''}
                    as={InputMask}
                    mask="99:99"
                    placeholder="Ex: 18:00"
                  />
                )}
              />
              <FormHelperText fontSize="sm">
                Formato 24 hrs (00:00 - 23:59)
              </FormHelperText>
            </FormControl>

            <Button
              colorScheme="teal"
              mt={[0, 0, '2rem']}
              isLoading={isSendingRequest}
            >
              {editingDay ? 'Salvar' : 'Adicionar'}
            </Button>
          </Grid>

          <Table mt={6}>
            <Thead>
              <Tr>
                <Th>Dia</Th>
                <Th>Horário</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {businessDays.map(({ id, day, start, end }) => (
                <Tr key={String(id)}>
                  <Td>{DAY_NAMES[day]}</Td>
                  <Td>
                    {excludeSecondsFromTime(start)} -{' '}
                    {excludeSecondsFromTime(end)}
                  </Td>
                  <Td>
                    <Grid templateColumns={['1fr', '3rem 3rem']} gap={2}>
                      <Button
                        colorScheme="red"
                        isLoading={deletingBusinessDayId === id}
                        onClick={() => handleDeleteButtonClick(id)}
                      >
                        <DeleteIcon />
                      </Button>
                      <Button
                        colorScheme="orange"
                        onClick={() => handleEditButtonClick(id)}
                      >
                        <EditIcon />
                      </Button>
                    </Grid>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>

      <ConfirmDelete
        isOpen={!!deletingBusinessDay}
        onClose={() => setDeletingBusinessDay(null)}
        onConfirm={() => handleDeleteConfirm(deletingBusinessDay?.id)}
        dialogTitle="Excluir horário"
        dialogContent={`Tem certeza que deseja excluir o horário de <b>${
          DAY_NAMES[deletingBusinessDay?.day] || ''
        }?</b>`}
      />
    </>
  );
}
