import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  Stack,
  Text,
  Heading,
  Box,
  Link,
  Flex,
  Tooltip,
  Fade,
  Button,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import PageHeading from '@components/PageHeading';
import { getStoreURL } from '@utils/get-store-url';

import api from '@services/api';

const Attribute = ({ title, value = '...' }) => (
  <Box>
    <Heading size="sm" mb={2}>
      {title}
    </Heading>
    <Text>{value}</Text>
  </Box>
);

const AttributeLink = React.memo(({ title, value = '...' }) => {
  const [copyLabel, setCopyLabel] = useState('Copiar');
  const inputRef = useRef(null);
  function handleCopy() {
    inputRef?.current?.focus();
    inputRef?.current?.select();
    document?.execCommand('copy');
    setCopyLabel('Copiado!');
  }

  return (
    <Box mb={4}>
      <Flex justifyContent="space-between">
        <Box maxW="90%">
          <Heading size="sm" mb={2}>
            {title}
          </Heading>

          <Link href={value} target="_blank" color="teal">
            {value}
          </Link>
        </Box>
        {value && (
          <input
            ref={inputRef}
            value={value}
            readOnly
            style={{ position: 'absolute', left: '-9999px', opacity: 0 }}
          />
        )}

        {value !== '...' && (
          <Tooltip
            label={copyLabel}
            placement="auto"
            closeOnClick={false}
            onClose={() => setCopyLabel('Copiar')}
          >
            <CopyIcon
              onClick={handleCopy}
              cursor="pointer"
              w={6}
              h={6}
              transition="all .35s ease"
              color="teal.500"
              _hover={{ filter: 'brightness(1.25)' }}
            />
          </Tooltip>
        )}
      </Flex>
    </Box>
  );
});
export default function Details() {
  const [store, setStore] = useState(null);
  const [isFetchingStore, setIsFetchingStore] = useState(false);

  const storeUrl = useMemo(
    () => (store ? getStoreURL({ nickname: store?.nickname }) : undefined),
    [store],
  );

  useEffect(() => {
    async function fetchStore() {
      try {
        setIsFetchingStore(true);
        const { data } = await api.get('stores');
        setStore(data);
      } finally {
        setIsFetchingStore(false);
      }
    }

    fetchStore();
  }, []);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <PageHeading>Detalhes</PageHeading>
        <Fade in={!isFetchingStore}>
          <Button as={RouterLink} colorScheme="teal" to="/edit-store">
            {store ? `Editar loja` : `Criar loja`}
          </Button>
        </Fade>
      </Flex>
      <Stack
        p={4}
        spacing={4}
        direction="column"
        rounded={4}
        shadow="lg"
        border="1px"
        borderColor="gray.200"
        mb={8}
      >
        <Attribute title="Nome da loja" value={store?.name} />
        <Attribute title="Whatsapp" value={store?.whatsapp} />
        <Attribute title="Endereço" value={store?.address} />
        <Attribute title="Descrição" value={store?.description} />
        <AttributeLink title="Link do cardápio" value={storeUrl} />
      </Stack>
    </>
  );
}
