import React, { useState, useEffect, useCallback } from 'react';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  CheckboxGroup,
  Checkbox,
  Stack,
} from '@chakra-ui/react';

// import { useQuery } from '@hooks/query';
import api from '@services/api';

function ChooseProduct({ value, onChange }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [availableProducts, setAvailableProducts] = useState([]);

  const handleRadioChange = useCallback(
    (values) => {
      const newValues = values.map((id) => ({
        id,
        name: availableProducts.find((product) => product.id === id).name,
      }));
      onChange(newValues);
    },
    [availableProducts, onChange],
  );
  useEffect(() => {
    async function loadCategories() {
      const response = await api.get('/products');
      setAvailableProducts(
        response.data.map(({ id, name, slug }) => ({
          id,
          name,
          slug,
        })),
      );
    }
    loadCategories();
  }, []);

  return (
    <>
      <Box
        _hover={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        color="teal"
        onClick={onOpen}
      >
        Selecionar
      </Box>
      <Modal
        blockScrollOnMount={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent m={2}>
          <ModalHeader>Selecione as categorias</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CheckboxGroup
              value={value?.map(({ id }) => id)}
              size="lg"
              onChange={handleRadioChange}
            >
              <Stack>
                {availableProducts.map(({ id, slug, name }) => (
                  <Checkbox key={slug} value={id}>
                    {name}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}

export default React.memo(ChooseProduct);
