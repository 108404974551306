import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Flex,
  Button,
  Box,
  Text,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  IconButton,
} from '@chakra-ui/react';

import { useAuth } from '@hooks/auth';

function AuthLayout({ children }) {
  const { signOut } = useAuth();

  const links = [
    {
      text: 'Página inicial',
      href: '/',
    },
    {
      text: 'Produtos',
      href: '/products',
    },
    {
      text: 'Complementos',
      href: '/complements',
    },
    {
      text: 'Editar loja',
      href: '/edit-store',
    },
    {
      text: 'Horário',
      href: '/edit-store-schedule',
    },
    {
      text: 'Checkout',
      href: '/edit-store-checkout',
    },
  ];

  const handleLogout = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <Flex direction="column" width="100%" height="100vh" alignItems="center">
      <Flex
        justifyContent="space-between"
        width="100%"
        p={4}
        border="1px"
        borderColor="gray.200"
        mb={[4, 10]}
      >
        <Flex>
          <Link to="/" style={{ display: 'flex', placeContent: 'center' }}>
            <img src="/logo.svg" alt="nextbox logo" width="140px" />
          </Link>
          <Box
            display={['none', 'none', 'initial']}
            alignSelf="stretch"
            width="1px"
            backgroundColor="gray.200"
            mx={4}
          />
        </Flex>

        <Flex
          alignItems="center"
          flex="1"
          color="gray.600"
          display={['none', 'none', 'flex']}
        >
          {links.map(({ text, href }, index) => (
            <ChakraLink
              key={index.toString()}
              as={Link}
              _hover={{ color: 'teal.500' }}
              to={href}
              marginLeft={index > 0 ? 4 : 0}
            >
              <Text>{text}</Text>
            </ChakraLink>
          ))}

          <Button
            marginLeft="auto"
            colorScheme="red"
            variant="link"
            onClick={handleLogout}
            fontWeight="normal"
          >
            Sair do sistema
          </Button>
        </Flex>

        <Menu>
          <MenuButton
            display={['initial', 'initial', 'none']}
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="outline"
          />
          <MenuList display={['block', 'block', 'none']}>
            {links.map(({ text, href }, index) => (
              <MenuItem
                key={index.toString()}
                as={Link}
                _hover={{ color: 'teal.500' }}
                to={href}
              >
                <Text>{text}</Text>
              </MenuItem>
            ))}
            <MenuDivider width="90%" marginLeft="auto" marginRight="auto" />
            <MenuItem color="red" onClick={handleLogout}>
              Sair do sistema
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <Flex alignSelf="stretch" flex="1" justifyContent="center" p={4}>
        <Flex direction="column" width="100%" maxWidth="1024px">
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AuthLayout;
