import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  Button,
  useToast,
  FormHelperText,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import PageHeading from '@components/PageHeading';
import api from '@services/api';
import CoverImageInput from './components/CoverImageInput';
import AvatarImageInput from './components/AvatarImageInput';

const nicknameRegex = /^[a-z0-9](?:[a-z0-9-]{0,61})[a-z0-9]$/;

function EditStore() {
  const history = useHistory();
  const toast = useToast();
  const [isFetching, setIsFetching] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [store, setStore] = useState(null);
  const [localAvatarFile, setLocalAvatarFile] = useState(null);
  const [localCoverFile, setLocalCoverFile] = useState(null);
  const [nicknameIsValid, setNicknameIsValid] = useState(true);

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await api.get('stores');
        setStore(data);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsFetching(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    setNicknameIsValid(nicknameRegex.test(store?.nickname || ''));
  }, [store]);

  function openToast(settings = {}) {
    toast({
      duration: 5000,
      isClosable: true,
      position: 'top',
      ...settings,
    });
  }

  async function uploadImage(file) {
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await api.post('files', formData);

    const { id, url } = data;

    return { id, url };
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const { target: form } = event;
    const method = store ? api.put : api.post;

    if (!nicknameIsValid) {
      openToast({
        title: 'Opss!',
        description: 'O apelido da loja está incorreto!',
        status: 'error',
      });

      return;
    }

    const avatar = localAvatarFile ? await uploadImage(localAvatarFile) : null;
    const cover = localCoverFile ? await uploadImage(localCoverFile) : null;

    const data = {
      name: form.name.value,
      nickname: form.nickname.value,
      whatsapp: form.whatsapp.value,
      address: form.address.value,
      description: form.description.value,
      avatar_image_id: avatar?.id || null,
      cover_image_id: cover?.id || null,
    };

    try {
      setIsSending(true);

      await method('stores', data);
      openToast({
        title: 'Tudo certo!',
        description: 'Dados enviados com sucesso!',
        status: 'success',
      });
      history.push('/');
    } catch (error) {
      console.log({ error });
      openToast({
        title: 'Opss!',
        description: 'Não foi possível realizar o registro!',
        status: 'error',
      });
    } finally {
      setIsSending(false);
    }
  }

  if (isFetching) {
    return <Text>Aguarde, estamos trazendo seus dados</Text>;
  }

  return (
    <Box alignSelf="center" maxWidth="400px" width="100%">
      <PageHeading mb={4}>Editar loja</PageHeading>
      <Stack
        gap={4}
        paddingTop="0"
        border="1px"
        borderColor="gray.200"
        as="form"
        gridTemplateColumns="1fr"
        shadow="lg"
        rounded={4}
        onSubmit={handleSubmit}
        overflow="hidden"
      >
        <Box position="relative" mb="4rem">
          <CoverImageInput
            preview={store?.coverImage?.url}
            onImageChange={setLocalCoverFile}
          />

          <AvatarImageInput
            preview={store?.avatarImage?.url}
            onImageChange={setLocalAvatarFile}
          />
        </Box>

        <Stack gap={4} p={4}>
          <FormControl isRequired>
            <FormLabel fontWeight="bold">Nome da loja:</FormLabel>
            <Input name="name" defaultValue={store?.name || undefined} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontWeight="bold">Apelido da loja:</FormLabel>
            <Input
              isInvalid={!nicknameIsValid}
              name="nickname"
              defaultValue={store?.nickname || undefined}
              errorBorderColor="red.300"
              onChange={({ target }) =>
                setNicknameIsValid(nicknameRegex.test(target.value))
              }
            />
            <FormHelperText>
              Este apelido vai estar no link da sua loja
              <br />
              Permitidos caracteres de a-z (Minúsculo)
              <br />
              Permitido números 0-9
              <br />
              Permitido hífen (-)
              <br />
              Máximo de 63 caracteres
              <br />
            </FormHelperText>
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontWeight="bold">Numero de WhatsApp:</FormLabel>
            <Input
              as={InputMask}
              mask="+55 99 99999-9999"
              name="whatsapp"
              defaultValue={store?.whatsapp || undefined}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontWeight="bold">Endereço:</FormLabel>
            <Input name="address" defaultValue={store?.address || undefined} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontWeight="bold">Descrição:</FormLabel>
            <Textarea
              name="description"
              defaultValue={store?.description || undefined}
            />
          </FormControl>
          <Button
            isLoading={isSending}
            colorScheme="teal"
            type="submit"
            size="lg"
          >
            Salvar
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default EditStore;
