import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Flex, Button, Fade, Divider } from '@chakra-ui/react';
import PageHeading from '@components/PageHeading';
import api from '@services/api';
import Details from './components/Details';

function Store() {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(true);
  const [store, setStore] = useState(null);

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await api.get('stores');
        setStore(data);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsFetching(false);
      }
    }

    loadData();
  }, []);

  function handleEditOrAddStore() {
    history.push('/edit-store');
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <PageHeading>Detalhes da loja</PageHeading>

        <Fade in={!isFetching}>
          <Button colorScheme="teal" onClick={handleEditOrAddStore}>
            {store ? `Editar loja` : `Criar loja`}
          </Button>
        </Fade>
      </Flex>
      <Divider my={4} />
      {isFetching && <Text>Buscando os dados...</Text>}
      {!isFetching && !store && <Text>Você precisa criar uma loja</Text>}
      {store && <Details store={store} />}
    </>
  );
}

export default Store;
