import React, { useState, useCallback, useRef } from 'react';
import {
  Link,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  CloseButton,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useCategory } from '@hooks/category';

import ConfirmDelete from '@components/ConfirmDelete';

function CategoryManager() {
  const {
    categories,
    loading,
    addNewCategory,
    editCategory,
    deleteCategory,
    editingCategoryId,
    deletingCategoryId,
  } = useCategory();

  const [isVisible, setIsVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [deletingCategory, setDeletingCategory] = useState(null);
  const inputRef = useRef(null);

  const handleOverlayClick = useCallback((event) => {
    event.preventDefault();

    const { target } = event;

    if (target?.id === 'category-overlay') setIsVisible(false);
  }, []);

  const handleSubmit = useCallback(() => {
    const {
      current: { value },
    } = inputRef;

    if (!value) return;

    const onSuccess = () => {
      setEditingCategory(null);
      Object.assign(inputRef?.current, { value: '' });
    };

    if (editingCategory) {
      editCategory({
        id: editingCategory.id,
        name: value,
        onSuccess,
      });
    } else {
      addNewCategory({
        name: value,
        onSuccess,
      });
    }
  }, [editingCategory, inputRef, addNewCategory, editCategory]);

  const handleEditButtonClick = useCallback(
    (index) => {
      const category = categories[index];

      setEditingCategory(category);
      const { current: input } = inputRef;

      Object.assign(input, { value: category.name });
    },

    [inputRef, categories],
  );

  const handleDeleteButtonClick = useCallback(
    (index) => {
      setDeletingCategory(categories[index]);
    },
    [categories],
  );

  const handleDeleteConfirmation = useCallback(() => {
    deleteCategory({ id: deletingCategory.id });
    setDeletingCategory(null);
  }, [deletingCategory, deleteCategory]);

  return (
    <>
      <Link
        position="absolute"
        top={0}
        right={0}
        color="teal.500"
        onClick={() => setIsVisible(true)}
      >
        Novo
      </Link>
      <Fade in={isVisible} unmountOnExit position="absolute">
        <Flex
          id="category-overlay"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          justifyContent="center"
          alignItems="center"
          backgroundColor="rgba(0,0,0,0.3)"
          zIndex={2}
          p={2}
          onClick={handleOverlayClick}
        >
          <Flex
            direction="column"
            maxW="440px"
            rounded={4}
            p={4}
            background="white"
            w="100%"
            position="relative"
          >
            <CloseButton
              background="white"
              position="absolute"
              top={2}
              right={2}
              onClick={() => setIsVisible(false)}
            />

            <Flex alignItems="flex-end" mb={4}>
              <FormControl w="70%" pr={4} isRequired>
                <FormLabel fontWeight="bold">Nome da categoria</FormLabel>
                <Input ref={inputRef} placeholder="Ex: bebidas" />
              </FormControl>
              <Button
                isLoading={loading}
                colorScheme="teal"
                w="30%"
                onClick={handleSubmit}
              >
                {editingCategory ? 'Atualizar' : 'Criar'}
              </Button>
            </Flex>

            <List direction="column" alignItems="stretch">
              {categories.map(({ id, name }, index) => (
                <ListItem
                  key={id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={2}
                  borderTop="1px solid transparent"
                  borderTopColor={index > 0 ? 'gray.200' : 'transparent'}
                >
                  <Text>{name}</Text>
                  <Flex>
                    <Button
                      disabled={loading}
                      isLoading={deletingCategoryId === id}
                      mr={4}
                      colorScheme="red"
                      onClick={() => handleDeleteButtonClick(index)}
                    >
                      <DeleteIcon />
                    </Button>
                    <Button
                      disabled={loading}
                      isLoading={editingCategoryId === id}
                      colorScheme="orange"
                      onClick={() => handleEditButtonClick(index)}
                    >
                      <EditIcon />
                    </Button>
                  </Flex>
                </ListItem>
              ))}
            </List>

            <ConfirmDelete
              isOpen={!!deletingCategory}
              onClose={() => setDeletingCategory(null)}
              onConfirm={handleDeleteConfirmation}
              dialogTitle="Excluir categoria"
              dialogContent={`
              Tem certeza que deseja deletar a categoria <b>${
                deletingCategory?.name || ''
              }</b>?
              `}
            />
          </Flex>
        </Flex>
      </Fade>
    </>
  );
}

export default CategoryManager;
