import React, { useState, useEffect, useCallback } from 'react';
import {
  Flex,
  Stack,
  Text,
  Heading,
  Checkbox,
  Button,
  Spinner,
  useToast,
} from '@chakra-ui/react';

import PageHeading from '@components/PageHeading';
import api from '@services/api';
import { useHistory } from 'react-router-dom';
import CardFlagManagement from './components/CardFlagManagement';

export default function EditStoreCheckout() {
  const toast = useToast();
  const history = useHistory();

  const [checkoutFieldsData, setCheckoutFieldsData] = useState(null);
  const [paymentMethodsData, setPaymentMethodsData] = useState(null);
  const [creditCardFlags, setCreditCardFlags] = useState([]);
  const [debitCardFlags, setDebitCardFlags] = useState([]);

  const [isFetchingCheckoutConfig, setIsFetchingCheckoutConfig] =
    useState(false);
  const [isSendingData, setIsSendingData] = useState(false);

  const blocked = isFetchingCheckoutConfig || isSendingData;

  function openToast(settings = {}) {
    toast({
      duration: 5000,
      isClosable: true,
      position: 'top',
      ...settings,
    });
  }

  useEffect(() => {
    async function fetchCheckoutConfig() {
      try {
        setIsFetchingCheckoutConfig(true);

        const [checkoutFieldsResponse, paymentMethodsResponse] =
          await Promise.all([
            api.get('/stores/checkout-fields').then(({ data }) => data),
            api.get('/stores/payment-methods').then(({ data }) => data),
          ]);

        ['store_id', 'created_at', 'updated_at'].forEach((key) => {
          delete checkoutFieldsResponse[key];
          delete paymentMethodsResponse[key];
        });

        setCheckoutFieldsData({
          name: checkoutFieldsResponse?.name,
          phone_number: checkoutFieldsResponse?.phone_number,
          address: checkoutFieldsResponse?.address,
          payment_method: checkoutFieldsResponse?.payment_method,
        });

        setPaymentMethodsData({
          cash: paymentMethodsResponse?.cash,
          credit_card: paymentMethodsResponse?.credit_card,
          debit_card: paymentMethodsResponse?.debit_card,
          pix: paymentMethodsResponse?.pix,
        });

        setCreditCardFlags(
          paymentMethodsResponse?.allowed_credit_card_flags || [],
        );

        setDebitCardFlags(
          paymentMethodsResponse?.allowed_debit_card_flags || [],
        );
      } catch (e) {
        // TODO: Handle error
        console.error(e);
      } finally {
        setIsFetchingCheckoutConfig(false);
      }
    }
    fetchCheckoutConfig();
  }, []);

  const handleCheckoutFieldChange = useCallback((event) => {
    const { name, checked } = event.target;

    setCheckoutFieldsData((oldData) => ({ ...oldData, [name]: checked }));
  }, []);

  const handlePaymentMethodChange = useCallback((event) => {
    const { name, checked } = event.target;
    const [, clearedName] = name.split('.');

    setPaymentMethodsData((oldData) => ({
      ...oldData,
      [clearedName]: checked,
    }));
  }, []);

  const handleRemoveCreditCardFlag = useCallback((value) => {
    setCreditCardFlags((oldFlags) => oldFlags.filter((flag) => flag !== value));
  }, []);

  const handleAddCreditCardFlag = useCallback((value) => {
    setCreditCardFlags((oldFlags) => {
      if (oldFlags.some((flag) => flag === value)) return oldFlags;
      return [...oldFlags, value];
    });
  }, []);

  const handleRemoveDebitCardFlag = useCallback((value) => {
    setDebitCardFlags((oldFlags) => oldFlags.filter((flag) => flag !== value));
  }, []);

  const handleAddDebitCardFlag = useCallback((value) => {
    setDebitCardFlags((oldFlags) => {
      if (oldFlags.some((flag) => flag === value)) return oldFlags;
      return [...oldFlags, value];
    });
  }, []);

  async function handleSubmit(event) {
    event?.preventDefault();

    if (isSendingData) return;

    try {
      setIsSendingData(true);

      await Promise.all([
        api.put('/stores/checkout-fields', checkoutFieldsData),
        api.put('/stores/payment-methods', {
          ...paymentMethodsData,
          allowed_credit_card_flags: creditCardFlags,
          allowed_debit_card_flags: debitCardFlags,
        }),
      ]);

      openToast({
        title: 'Tudo certo!',
        description: 'Dados atualizados com sucesso!!',
        status: 'success',
      });

      history.push('/');
    } catch (err) {
      console.error(err);
      openToast({
        title: 'Opss!',
        description: 'Não foi possível enviar as informações, tente novamente!',
        status: 'error',
      });
    } finally {
      setIsSendingData(false);
    }
  }

  return (
    <Flex direction="column" alignItems="stretch">
      <Flex mb={4} justifyContent="space-between" alignItems="center">
        <PageHeading>Configurações do Checkout</PageHeading>

        {isFetchingCheckoutConfig && <Spinner size="sm" />}
      </Flex>

      <Flex
        direction="column"
        p={4}
        shadow="lg"
        border="1px"
        borderColor="gray.200"
        rounded={4}
        mb={4}
      >
        <Heading fontSize={['lg', 'xl']} mb={4}>
          Formulário de compra
        </Heading>

        <Text mb={2}>Selecione os campos que o cliente deverá preencher:</Text>

        <Stack spacing={2} direction="column" mb={8}>
          <Checkbox
            isDisabled={blocked}
            isChecked={checkoutFieldsData?.name || false}
            name="name"
            onChange={handleCheckoutFieldChange}
          >
            Nome
          </Checkbox>
          <Checkbox
            isDisabled={blocked}
            isChecked={checkoutFieldsData?.phone_number || false}
            name="phone_number"
            onChange={handleCheckoutFieldChange}
          >
            Número de telefone
          </Checkbox>
          <Checkbox
            isDisabled={blocked}
            isChecked={checkoutFieldsData?.address || false}
            name="address"
            onChange={handleCheckoutFieldChange}
          >
            Endereço
          </Checkbox>
          <Checkbox
            isDisabled={blocked}
            isChecked={checkoutFieldsData?.payment_method || false}
            name="payment_method"
            onChange={handleCheckoutFieldChange}
          >
            Método de pagamento
          </Checkbox>
        </Stack>

        <Heading fontSize={['lg', 'xl']} mb={4}>
          Formas de pagamento
        </Heading>

        <Text mb={2}>Selecione as formas que o cliente pode pagar:</Text>

        <Stack spacing={2} direction="column" mb={8}>
          <Checkbox
            isDisabled={blocked}
            isChecked={paymentMethodsData?.cash || false}
            name="payment_methods.cash"
            onChange={handlePaymentMethodChange}
          >
            Dinheiro
          </Checkbox>
          <Checkbox
            isDisabled={blocked}
            isChecked={paymentMethodsData?.pix || false}
            name="payment_methods.pix"
            onChange={handlePaymentMethodChange}
          >
            PIX
          </Checkbox>
          <Checkbox
            isDisabled={blocked}
            isChecked={paymentMethodsData?.credit_card || false}
            name="payment_methods.credit_card"
            onChange={handlePaymentMethodChange}
          >
            Cartão de Crédito (Entregador)
          </Checkbox>
          <Checkbox
            isDisabled={blocked}
            isChecked={paymentMethodsData?.debit_card || false}
            name="payment_methods.debit_card"
            onChange={handlePaymentMethodChange}
          >
            Cartão de Débito (Entregador)
          </Checkbox>
        </Stack>

        <Heading fontSize={['lg', 'xl']} mb={4}>
          Bandeiras de cartão de crédito
        </Heading>

        <CardFlagManagement
          values={creditCardFlags}
          addNewValue={handleAddCreditCardFlag}
          removeValue={handleRemoveCreditCardFlag}
          placeholder="Bandeira do cartão de crédito"
          mb={8}
        />

        <Heading fontSize={['lg', 'xl']} mb={4}>
          Bandeiras de cartão de débito
        </Heading>

        <CardFlagManagement
          values={debitCardFlags}
          addNewValue={handleAddDebitCardFlag}
          removeValue={handleRemoveDebitCardFlag}
          placeholder="Bandeira do cartão de débito"
          mb={8}
        />
      </Flex>

      <Button
        isLoading={blocked}
        onClick={handleSubmit}
        colorScheme="teal"
        size="lg"
        type="button"
      >
        Salvar
      </Button>
    </Flex>
  );
}
