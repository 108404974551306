import React, { useState, useEffect } from 'react';
import { Flex, Button, Text, Image, Divider, Badge } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import PageHeading from '@components/PageHeading';
import api from '@services/api';
import toCurrency from '@utils/number-format';

function Products() {
  const [products, setProducts] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await api.get('products');
        setProducts(data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsFetching(false);
      }
    }

    loadData();
  }, []);

  function handleProductClick(productId) {
    history.push(`/product/${productId}`);
  }

  return (
    <>
      <Flex
        alignSelf="stretch"
        alignItems="center"
        justifyContent="space-between"
      >
        <PageHeading maxWidth={['120px', '50%']}>
          Produtos cadastrados
        </PageHeading>
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => history.push('/edit-product')}
        >
          Cadastrar produto
        </Button>
      </Flex>

      <Divider my={4} />

      {isFetching && <Text>Carregando...</Text>}
      {!isFetching && products.length === 0 && (
        <Text>Nenhum produto cadastrado</Text>
      )}

      {products.map(
        ({ id, image, name, description, price, active }, index) => (
          <Flex
            key={id}
            onClick={() => handleProductClick(id)}
            cursor="pointer"
            transition="all .35s ease"
            _hover={{
              shadow: 'xl',
            }}
            p={2}
            rounded={4}
            shadow="lg"
            border="1px"
            borderColor="gray.200"
            mt={index > 0 ? 4 : 0}
          >
            <Image
              src={image?.url || '/full-logo.webp'}
              height={['70px', '90px']}
              width={['70px', '90px']}
              objectFit="cover"
              mr={4}
              rounded={4}
            />
            <Flex flex="1" direction="column">
              <Flex>
                <Text fontWeight="600" mr={2}>
                  {name}
                </Text>
                {active ? (
                  <Badge alignSelf="center" rounded={4} colorScheme="green">
                    Ativado
                  </Badge>
                ) : (
                  <Badge alignSelf="center" rounded={4} colorScheme="red">
                    Desativado
                  </Badge>
                )}
              </Flex>
              <Text>{description}</Text>
              <Text mt={2} fontWeight="600" color="teal.600">
                {toCurrency(price)}
              </Text>
            </Flex>
          </Flex>
        ),
      )}
    </>
  );
}

export default Products;
