import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import isPast from 'date-fns/isPast';
import toDate from 'date-fns/toDate';

import { STORAGE_KEY } from '@utils/constants.js';
import { decode } from '@utils/jwt';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(
    (() => {
      const token = localStorage.getItem(`${STORAGE_KEY}:token`);
      const user = localStorage.getItem(`${STORAGE_KEY}:user`);

      if (token && user) {
        return { token, user: JSON.parse(user) };
      }

      return {};
    })(),
  );

  useEffect(() => {
    function loadStorageDate() {
      const token = localStorage.getItem(`${STORAGE_KEY}:token`);
      const user = localStorage.getItem(`${STORAGE_KEY}:user`);

      if (token && user) {
        setData({ token, user: JSON.parse(user) });
      }
    }

    loadStorageDate();
  }, []);

  const signIn = useCallback(({ user, token }) => {
    localStorage.setItem(`${STORAGE_KEY}:token`, token);
    localStorage.setItem(`${STORAGE_KEY}:user`, JSON.stringify(user));

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(`${STORAGE_KEY}:token`);
    localStorage.removeItem(`${STORAGE_KEY}:user`);
    setData({});
  }, []);

  const isValidSession = useCallback(() => {
    const { token } = data;

    if (!token) return false;

    const { exp } = decode(token);
    const expireDate = toDate(exp * 1000);

    return !isPast(expireDate);
  }, [data]);

  return (
    <AuthContext.Provider
      value={{ user: data?.user, signIn, signOut, isValidSession }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
