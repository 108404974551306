import React from 'react';

import Details from './components/Details';
import ProductsPreview from './components/ProductsPreview';

export default function Dashboard() {
  return (
    <>
      <Details />
      <ProductsPreview />
    </>
  );
}
