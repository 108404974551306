import React from 'react';

import { HStack, Flex, Text, Button } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import numberFormat from '@utils/number-format';

function ListOptions({ options = [], onDelete = () => {}, onEdit = () => {} }) {
  return (
    <Flex direction="column">
      {options
        .filter(({ deleted }) => !deleted)
        .map(({ id, name, price }) => (
          <Flex
            key={id}
            justifyContent="space-between"
            p={2}
            borderColor="gray.200"
            borderTopWidth="1px"
            borderStyle="solid"
            _last={{ borderBottomWidth: '1px' }}
          >
            <Flex direction="column">
              <Text fontWeight="bold">{name}</Text>
              <Text fontSize="sm">{numberFormat(price)}</Text>
            </Flex>

            <HStack alignSelf="center">
              <Button
                as="div"
                colorScheme="yellow"
                size="sm"
                variant="outline"
                cursor="pointer"
                leftIcon={<EditIcon />}
                onClick={() => onEdit(id)}
              >
                Editar
              </Button>

              <Button
                as="div"
                colorScheme="red"
                size="sm"
                variant="outline"
                cursor="pointer"
                leftIcon={<DeleteIcon />}
                onClick={() => onDelete(id)}
              >
                Excluir
              </Button>
            </HStack>
          </Flex>
        ))}
    </Flex>
  );
}

export default React.memo(ListOptions);
