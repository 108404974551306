import React from 'react';
import { Box, Heading, Text, Stack, Badge } from '@chakra-ui/react';

import numberFormat from '@utils/number-format';

function Details({ product }) {
  return (
    <Stack spacing={4}>
      <Box>
        <Heading size="sm" mb={2}>
          Nome
        </Heading>
        <Text>{product.name}</Text>
      </Box>
      <Box>
        <Heading size="sm" mb={2}>
          Preço
        </Heading>
        <Text>{numberFormat(product.price)}</Text>
      </Box>
      <Box>
        <Heading size="sm" mb={2}>
          Category
        </Heading>
        <Text>{product?.category?.name || 'Sem categoria'}</Text>
      </Box>
      <Box>
        <Heading size="sm" mb={2}>
          Description
        </Heading>
        <Text>{product.description}</Text>
      </Box>
      <Box>
        {product.active ? (
          <Badge p={1} rounded={4} colorScheme="green">
            Produto Ativado
          </Badge>
        ) : (
          <Badge p={1} rounded={4} colorScheme="red">
            Produto Desativado
          </Badge>
        )}
      </Box>
    </Stack>
  );
}

export default Details;
