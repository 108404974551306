import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import Route from '@components/Route';

import {
  Products,
  EditProduct,
  Register,
  Login,
  // Store,
  EditStore,
  Product,
  Dashboard,
  EditStoreCheckout,
  EditStoreSchedule,
  Complements,
  EditComplement,
} from '@pages/index';

const RouteWrapper = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Dashboard} isPrivate />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/products" component={Products} isPrivate />
      <Route path="/complements" component={Complements} isPrivate />
      <Route path="/new-complement" component={EditComplement} isPrivate />
      <Route path="/edit-complement/:id" component={EditComplement} isPrivate />

      <Route path="/edit-product" component={EditProduct} isPrivate />
      {/* <Route path="/store" component={Store} isPrivate /> */}
      <Route path="/edit-store" component={EditStore} isPrivate />
      <Route path="/product/:productId" component={Product} isPrivate />
      <Route
        path="/edit-store-checkout"
        component={EditStoreCheckout}
        isPrivate
      />
      <Route
        path="/edit-store-schedule"
        component={EditStoreSchedule}
        isPrivate
      />
    </Switch>
  </BrowserRouter>
);

export default RouteWrapper;
