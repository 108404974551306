import React from 'react';
import { Heading } from '@chakra-ui/react';

function PageHeading({ children, ...rest }) {
  return (
    <Heading fontSize={['lg', 'xl']} color="gray.700" {...rest}>
      {children}
    </Heading>
  );
}

export default PageHeading;
