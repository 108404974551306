import React, { useState } from 'react';
import {
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Link,
  useDisclosure,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalContent,
  Text,
  Image,
  FormHelperText,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import api from '@services/api';

function Register() {
  const history = useHistory();
  const toast = useToast();

  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const [isSending, setIsSending] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const passwordsNotMatch = password !== confirmPassword;

  function openToast(settings = {}) {
    toast({
      duration: 5000,
      isClosable: true,
      position: 'top',
      ...settings,
    });
  }

  async function handleSubmit(event) {
    setIsSending(true);
    try {
      event.preventDefault();

      if (passwordsNotMatch) throw new Error('As senhas não são iguais!');

      const { target: form } = event;

      const name = form.fullName.value;
      const email = form.email.value;
      const whatsapp = form.whatsapp.value;

      await api.post('users', { name, email, password, whatsapp });

      openToast({
        title: 'Cadastro realizado.',
        description: 'Sua conta foi criada com sucesso!',
        status: 'success',
      });

      modalOnOpen();
    } catch (error) {
      console.log({ error });
      openToast({
        title: 'Erro ao criar conta.',
        description:
          error?.response?.data ||
          error?.message ||
          'Tente novamente mais tarde.',
        status: 'error',
      });
      setIsSending(false);
    }
  }

  function handleModalClose() {
    history.push('/login');
    modalOnClose();
  }

  return (
    <>
      <Flex
        height="100vh"
        direction="column"
        alignItems="center"
        justifyContent="center"
        p={6}
      >
        <Image
          src="/logo.svg"
          width={['175px', '200px', '240px']}
          mb={8}
          mt={20}
        />

        <Flex
          as="form"
          p={8}
          rounded={6}
          direction="column"
          width="100%"
          maxWidth="400px"
          shadow="xl"
          onSubmit={handleSubmit}
          border="1px"
          borderColor="gray.200"
        >
          <Heading mb={6} size="md" alignSelf="center">
            Crie sua conta
          </Heading>
          <FormControl isRequired>
            <FormLabel fontWeight="bold">Nome completo</FormLabel>
            <Input mb={4} name="fullName" required />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontWeight="bold">Email</FormLabel>
            <Input mb={4} type="email" name="email" required />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontWeight="bold">Numero de WhatsApp:</FormLabel>
            <Input
              as={InputMask}
              mask="+55 99 99999-9999"
              name="whatsapp"
              required
            />
            <FormHelperText mb={2}>
              Vamos entrar em contato para auxilia-lo(a)
            </FormHelperText>
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontWeight="bold">Senha</FormLabel>
            <Input
              type="password"
              mb={4}
              name="password"
              required
              errorBorderColor="red.300"
              onChange={({ target }) => setPassword(target.value)}
              value={password}
              isInvalid={passwordsNotMatch}
              minLength="6"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontWeight="bold">Confirmação de senha</FormLabel>
            <Input
              type="password"
              mb={8}
              name="confirmPassword"
              required
              errorBorderColor="red.300"
              onChange={({ target }) => setConfirmPassword(target.value)}
              value={confirmPassword}
              isInvalid={passwordsNotMatch}
              minLength="6"
            />
          </FormControl>

          <Button
            colorScheme="teal"
            size="lg"
            variant="outline"
            type="submit"
            isLoading={isSending}
          >
            Enviar
          </Button>

          <Link
            mt={5}
            color="teal.500"
            size="md"
            alignSelf="center"
            textAlign="center"
            onClick={() => history.push('/login')}
          >
            Já é registrado? Acesse a loja
          </Link>
        </Flex>
      </Flex>
      <Modal
        blockScrollOnMount={false}
        isOpen={modalIsOpen}
        onClose={handleModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verifique seu email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="1rem">
              Enviamos um link de confirmação para o seu e-mail, por favor
              verifique.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleModalClose}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Register;
