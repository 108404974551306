import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import {
  Flex,
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  Switch,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from '@chakra-ui/react';

import { v4 as uniqueId } from 'uuid';
import api from '@services/api';

import PageHeading from '@components/PageHeading';
import { ChooseItem, ManageOption, ListOptions } from './components';

export default function EditOrAddComplement() {
  const { register, watch, setValue, reset, control, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      multiple_choice: false,
      required: false,
      max: null,
      categories: [],
      products: [],
      options: [],
    },
  });

  const { id: complementId } = useParams();

  const history = useHistory();
  const toast = useToast();

  const [currentCategories, currentProducts, currentOptions] = watch([
    'categories',
    'products',
    'options',
  ]);

  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);

  const categoriesToDisplay = useMemo(
    () =>
      currentCategories
        .map(
          (id) =>
            availableCategories.find((c) => String(c.id) === String(id))?.name,
        )
        .filter(Boolean),
    [availableCategories, currentCategories],
  );

  const productsToDisplay = useMemo(
    () =>
      currentProducts
        .map(
          (id) =>
            availableProducts.find((p) => String(p.id) === String(id))?.name,
        )
        .filter(Boolean),
    [availableProducts, currentProducts],
  );

  const manageOptionRef = useRef();

  useEffect(() => {
    async function loadCategoryAndProducts() {
      const [categoriesResponse, productsResponse] = await Promise.all([
        api.get('/stores/categories'),
        api.get('/products'),
      ]);

      setAvailableCategories(
        categoriesResponse.data.map(({ id, name, slug }) => ({
          id: String(id),
          name,
          slug,
        })),
      );

      setAvailableProducts(
        productsResponse.data.map(({ id, name, slug }) => ({
          id,
          name,
          slug,
        })),
      );
    }

    loadCategoryAndProducts();
  }, []);

  useEffect(() => {
    if (!complementId || !reset) return;

    async function loadComplement() {
      const {
        data: {
          name,
          // eslint-disable-next-line camelcase
          multiple_choice,
          required,
          max,
          options,
          products,
          categories,
        },
      } = await api.get(`/stores/complements/${complementId}`);

      const parsedData = {
        name,
        multiple_choice,
        required,
        max,
        categories,
        products,
        options,
      };

      reset(parsedData);
    }

    loadComplement();
  }, [complementId, reset]);

  const openToast = useCallback(
    (settings = {}) => {
      toast({
        duration: 5000,
        isClosable: true,
        position: 'top',
        ...settings,
      });
    },
    [toast],
  );

  const handleOptionSave = useCallback(
    (data) => {
      let newOptions;

      if (data?.id === undefined || data?.id === null) {
        Object.assign(data, {
          id: uniqueId(),
          new: true,
        });

        newOptions = [...currentOptions, data];
      } else {
        newOptions = currentOptions.map((option) =>
          option.id === data.id ? { ...option, ...data, edited: true } : option,
        );
      }

      setValue('options', newOptions);
    },
    [currentOptions],
  );

  const handleOptionDelete = useCallback(
    (id) => {
      const optionToDelete = currentOptions.find((option) => option.id === id);

      if (optionToDelete?.new) {
        setValue(
          'options',
          currentOptions.filter((option) => option.id !== id),
        );
      } else {
        setValue(
          'options',
          currentOptions.map((option) =>
            option.id === id ? { ...option, deleted: true } : option,
          ),
        );
      }
    },
    [currentOptions],
  );

  const handleOptionEdit = useCallback(
    (id) => {
      const optionToEdit = currentOptions.find((option) => option.id === id);

      manageOptionRef.current?.open(optionToEdit);
    },
    [manageOptionRef, currentOptions],
  );

  const handleFormSubmit = useCallback(
    async (data) => {
      // Normalize data before sending

      const method = complementId ? api.put : api.post;
      const endPoint = complementId
        ? `/stores/complements/${complementId}`
        : '/stores/complements';

      method(endPoint, data)
        .then(() => {
          openToast({
            title: complementId
              ? 'Complemento alterado com sucesso'
              : 'Complemento criado com sucesso!',
            status: 'success',
          });

          history.push('/complements');
        })
        .catch((error) => {
          console.log(error);
          openToast({
            title: 'Erro ao criar complemento',
            description: 'Tente novamente mais tarde',
            status: 'error',
          });
        });
    },
    [complementId],
  );

  return (
    <>
      <Flex direction="column" alignItems="stretch">
        <PageHeading mb={4}>
          {complementId ? 'Editar' : 'Novo'} complemento
        </PageHeading>
      </Flex>

      <Flex
        direction="column"
        p={4}
        shadow="lg"
        border="1px"
        borderColor="gray.200"
        rounded={4}
      >
        <Grid
          as="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          templateColumns={['1fr']}
          gap={4}
        >
          <FormControl isRequired>
            <FormLabel fontWeight="bold">Nome:</FormLabel>
            <Input
              {...register(`name`, { required: true })}
              placeholder="Ex: tipo de queijo"
            />
          </FormControl>

          <FormControl>
            <FormLabel fontWeight="bold">
              Quantidade máxima de opções:
            </FormLabel>

            <Controller
              control={control}
              name="max"
              render={({ field: { value, onChange } }) => (
                <NumberInput
                  value={value ? String(value) : ''}
                  onChange={(inputValue) => {
                    const parsedValue = inputValue ? Number(inputValue) : null;

                    onChange(parsedValue < 0 ? null : parsedValue);
                  }}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              )}
            />

            <FormHelperText fontSize="sm">
              Deixe em branco para não ter limite
            </FormHelperText>
          </FormControl>

          <FormControl>
            <Flex alignItems="center" justifyContent="space-between" mb={2}>
              <FormLabel fontWeight="bold" mb="0">
                Categorias associadas:
              </FormLabel>

              <Controller
                control={control}
                name="categories"
                render={({ field: { value, onChange } }) => (
                  <ChooseItem
                    items={availableCategories}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Flex>
            <Input
              cursor="not-allowed"
              variant="filled"
              readOnly
              value={categoriesToDisplay.join(`, `) || `Nenhuma`}
            />
          </FormControl>

          <FormControl>
            <Flex alignItems="center" justifyContent="space-between" mb={2}>
              <FormLabel fontWeight="bold" mb="0">
                Produtos associados:
              </FormLabel>

              <Controller
                control={control}
                name="products"
                render={({ field: { value, onChange } }) => (
                  <ChooseItem
                    items={availableProducts}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Flex>

            <Input
              cursor="not-allowed"
              variant="filled"
              readOnly
              value={productsToDisplay.join(`, `) || `Nenhum`}
            />
          </FormControl>

          <FormControl>
            <Flex alignItems="center" justifyContent="space-between" mb={2}>
              <FormLabel fontWeight="bold" mb="0">
                Opções:
              </FormLabel>

              <Button
                as="div"
                colorScheme="teal"
                variant="link"
                fontWeight="normal"
                type="button"
                role="button"
                cursor="pointer"
                onClick={() => manageOptionRef?.current?.open()}
              >
                Adicionar
              </Button>
            </Flex>

            <ManageOption ref={manageOptionRef} onSave={handleOptionSave} />

            <ListOptions
              options={currentOptions}
              onDelete={handleOptionDelete}
              onEdit={handleOptionEdit}
            />
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel fontWeight="bold" htmlFor="multiple_choice" mb="0">
              Múltipla escolha:
            </FormLabel>

            <Controller
              control={control}
              name="multiple_choice"
              render={({ field: { value, onChange } }) => (
                <Switch
                  isChecked={!!value}
                  onChange={onChange}
                  id="multiple_choice"
                />
              )}
            />
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel fontWeight="bold" htmlFor="is-required" mb="0">
              Seleção obrigatório:
            </FormLabel>
            <Controller
              control={control}
              name="required"
              render={({ field: { value, onChange } }) => (
                <Switch
                  isChecked={!!value}
                  onChange={onChange}
                  id="is-required"
                />
              )}
            />
          </FormControl>

          <Button type="submit" colorScheme="teal" mt={2}>
            Salvar
          </Button>
        </Grid>
      </Flex>
    </>
  );
}
